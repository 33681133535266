const createReducer = reducerFuncs => {
  const reducer = (state, { type, payload, ...others }) => {
    const dispatchTypeFn = reducerFuncs[type]

    if (dispatchTypeFn) {
      return dispatchTypeFn(state, { payload, ...others })
    }

    console.error(
      `Dispatched Type: ${type} on Reducer is not defined. Returning current state!`
    )
    return state
  }

  return reducer
}

export default createReducer
