export const redeemCode = async code => {
  const response = await fetch(`/api/auth/code?code=${code}`)
  const json = await response.json()

  if (!response.ok || 'error' in json) throw new Error()

  return json
}

export const refreshTokens = async refreshToken => {
  const response = await fetch(`/api/auth/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      refreshToken
    })
  })

  if (!response.ok) throw new Error()

  return response.json()
}

export const fetchUser = async token => {
  const response = await fetch(`/api/auth/user`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (!response.ok) throw new Error()

  return response.json()
}
