const store = window.localStorage

export const fetchTokens = _ => {
  const token = store.getItem('token')
  const refreshToken = store.getItem('refreshToken')
  const tokenExpiresOn = store.getItem('tokenExpiresOn')

  return { token, refreshToken, tokenExpiresOn }
}

export const setTokens = async (tokens = {}) => {
  const { accessToken, refreshToken, expiresIn } = tokens

  accessToken && store.setItem('token', accessToken)
  refreshToken && store.setItem('refreshToken', refreshToken)
  expiresIn && store.setItem('tokenExpiresOn', Date.now() + expiresIn * 1000)
}

export const clearTokens = _ => {
  store.removeItem('token')
  store.removeItem('refreshToken')
  store.removeItem('tokenExpiresOn')
}
