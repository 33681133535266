const formatDate = (date, time) => {
  const options = { hour: 'numeric', minute: 'numeric' }

  const startUnix = Date.parse(date.split('T')[0] + ' ' + time + 'Z')
  const formattedDate = new Date(startUnix)

  return formattedDate.toLocaleDateString('de-DE', options)
}

const toHHMMSS = time => {
  const timeArray = time.split(':')
  const isTimestampInvalid = timeArray.length <= 2

  if (isTimestampInvalid) {
    while (timeArray.length <= 2) {
      timeArray.push('00')
    }
  }

  return timeArray.join(':')
}

const msToReadable = timestamp =>
  new Date(timestamp).toISOString().slice(11, 19)

export { formatDate, toHHMMSS, msToReadable }
