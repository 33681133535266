export const ORCA_BASE = window.location
export const ORCA_PARAMS = new URLSearchParams(ORCA_BASE.search)

export class AuthEndpoints {
  static get SSO_PARAMS() {
    return new URLSearchParams({
      client_id: window.config.auth.CLIENT_ID,
      redirect_uri: ORCA_BASE.origin,
      response_type: 'code'
    })
  }

  static get AUTH_BASE() {
    return window.config.auth.AUTH_BASE
  }

  static get LOGIN_REDIRECT() {
    return `${
      AuthEndpoints.AUTH_BASE
    }/oauth/authorize?${AuthEndpoints.SSO_PARAMS.toString()}`
  }

  static get SIGN_OUT_REDIRECT() {
    return `${AuthEndpoints.AUTH_BASE}/users/sign_out?redirect_url=${ORCA_BASE.origin}`
  }
}
