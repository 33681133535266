import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 240

const styles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButtonHidden: {
    display: 'none'
  },
  titleContainer: {
    // Remove Anchor Default styling
    color: 'unset',
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      // Stops search bar changing size when drawer opened/closed,
      minWidth: '130px',
      display: 'flex',
      alignItems: 'center'
    }
  },
  mobileHide: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  toolbar: {
    justifyContent: 'space-between'
  },
  logo: { padding: '0 12px 0 22px', height: '25px' },
  logoMenuOpen: { paddingLeft: '0' },
  anchorUnset: {
    textDecoration: 'none',
    color: 'unset'
  },
  searchResultsContainer: {
    position: 'fixed',
    overflowY: 'scroll',
    top: '80px',
    bottom: 0,
    maxHeight: '50%',
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      width: '300px'
    },
    [theme.breakpoints.up('md')]: {
      width: '500px'
    }
  }
}))

export default styles
