import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { api } from '../../Api'

import {
  AppBar,
  Toolbar,
  Typography,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  List,
  ListItem,
  ListItemText,
  // ListItemSecondaryAction,
  ListItemIcon,
  Divider
} from '@material-ui/core'

import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
  Videocam as VideoCamIcon,
  Lock as LockIcon,
  LockOpen as LockOpenIcon
} from '@material-ui/icons/'

import styles from './styles'
import UserContext from '../../Context/userContext'
import { SettingsContextConsumer } from '../../Context/settingsContext'
import { handleSignOut } from '../../Auth'
import Search from '../Search'
import Backdrop from '../Backdrop'
import { get } from 'lodash'

import Logo from '../../Assets/Logo'

const Header = ({ handleDrawerOpen, open }) => {
  const classes = styles()
  const [userAnchor, setUserAnchor] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [searchLoading, setSearchLoading] = useState(false)

  // Search 1.5 seconds after the user has finished typing
  let searchTimeout = null

  const handleMenuOpen = ({ currentTarget }) => setUserAnchor(currentTarget)
  const handleMenuClose = _ => setUserAnchor(null)

  const handleSearch = ({ currentTarget }) => {
    searchTimeout && clearTimeout(searchTimeout)

    const { value } = currentTarget

    // Clear search results if search term empty/whitespace
    if (value.trim().length === 0) {
      setSearchLoading(false)
      return setSearchResults([])
    }

    searchTimeout = setTimeout(_ => {
      setSearchLoading(true)
      api(`/api/devices/search/${value}`)
        .then(r => r.json())
        .then(json => {
          setSearchResults(json)
          setSearchLoading(false)
        })
        .catch(console.error)
    }, 750)
  }

  const handleSearchClose = _ => {
    setSearchLoading(false)
    setSearchResults([])
  }

  const searchResultMapper = ({ thingName, attributes }, index) => (
    <Link
      to={`/devices/${thingName}`}
      className={classes.anchorUnset}
      key={index}
    >
      <ListItem onClick={handleSearchClose}>
        <ListItemIcon>
          <VideoCamIcon />
        </ListItemIcon>
        <ListItemText
          primary={thingName}
          secondary={get(attributes, 'SITE') || 'No Site'}
        />
        {/* Show the broadcasting state in the secondaryAction */}
        {/* <ListItemSecondaryAction>
          <IconButton edge='end' aria-label='delete'></IconButton>
        </ListItemSecondaryAction> */}
      </ListItem>
      <Divider />
    </Link>
  )

  return (
    <AppBar
      position='absolute'
      className={clsx(classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.titleContainer}>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Link
            className={clsx([classes.titleContainer, classes.mobileHide])}
            to='/'
          >
            <Logo
              className={clsx(classes.logo, open && classes.logoMenuOpen)}
            />
            <Typography
              component='h1'
              variant='h6'
              color='inherit'
              noWrap
              className={classes.title}
            >
              ORCA
            </Typography>
          </Link>
        </div>
        <div>
          <Search onChange={handleSearch} isLoading={searchLoading} />
          {searchResults.length !== 0 && (
            <div>
              <Paper className={classes.searchResultsContainer}>
                <List>{searchResults.map(searchResultMapper)}</List>
              </Paper>
              <Backdrop onClick={handleSearchClose} />
            </div>
          )}
        </div>
        <div>
          <SettingsContextConsumer>
            {({ state: { lock }, dispatch }) => (
              <IconButton
                color='inherit'
                onClick={_ => dispatch({ type: 'TOGGLE_LOCK' })}
              >
                <Badge color='secondary'>
                  {lock ? <LockIcon /> : <LockOpenIcon />}
                </Badge>
              </IconButton>
            )}
          </SettingsContextConsumer>

          <UserContext.Consumer>
            {user => (
              <>
                <IconButton color='inherit' onClick={handleMenuOpen}>
                  <AccountCircleIcon />
                </IconButton>
                <Menu
                  anchorEl={userAnchor}
                  open={Boolean(userAnchor)}
                  onClose={handleMenuClose}
                >
                  <MenuItem disabled>My Account</MenuItem>
                  <MenuItem onClick={handleSignOut}>Logout</MenuItem>
                </Menu>
              </>
            )}
          </UserContext.Consumer>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Header
