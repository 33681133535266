import { lazy } from 'react'
import Events from './Pages/Events'

export const ROUTES = [
  {
    path: '/devices/:deviceName',
    component: lazy(_ => import('./Pages/Device'))
  },
  {
    path: '/camera-overview',
    component: lazy(_ => import('./Pages/CameraOverview'))
  },
  { path: '/livestream', component: lazy(_ => import('./Pages/Livestream')) },
  { path: '/devices', component: lazy(_ => import('./Pages/Devices')) },
  { path: '/events', component: Events },
  { path: '/mosaic', component: lazy(_ => import('./Pages/Mosaic')) },
  { path: '/rules', component: lazy(_ => import('./Pages/Rules')) },
  { path: '/batch', component: lazy(_ => import('./Pages/Batch')) },
  { path: '/authz', component: lazy(_ => import('./Pages/Authz')) },
  { path: '/', component: Events }
]
