import React from 'react'
import { Typography, Button } from '@material-ui/core'

import { handleSignOut } from './index'

const UnauthorizedContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  backgroundColor: '#eaeff1'
}

const Unauthorized = _ => {
  return (
    <div style={UnauthorizedContainer}>
      <Typography variant='h1' component='h2' gutterBottom>
        401
      </Typography>
      <Typography variant='h5' gutterBottom>
        Your account is <b>not</b> authorized to access Orca-AP
      </Typography>
      <Typography variant='h5' gutterBottom>
        Please use a <b>@sporttotal.tv</b> or <b>@sporttotal.com</b> account to
        log-in to Orca-AP
      </Typography>
      <Button
        size='large'
        variant='outlined'
        color='secondary'
        onClick={handleSignOut}
        style={{ marginTop: '30px' }}
      >
        Log-In
      </Button>
    </div>
  )
}

export default Unauthorized
