import React from 'react'
import { InputBase, CircularProgress } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons/'

import styles from './styles'

const Search = ({ onChange, isLoading }) => {
  const classes = styles()

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        {isLoading ? (
          <CircularProgress size={18} className={classes.searchLoading} />
        ) : (
          <SearchIcon />
        )}
      </div>
      <InputBase
        placeholder='Search…'
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        inputProps={{ 'aria-label': 'search' }}
        onChange={onChange}
      />
    </div>
  )
}

export default Search
