import React from 'react'
import { Link } from 'react-router-dom'
import { Chip } from '@material-ui/core'
import { PlayCircleFilled, PauseCircleFilled } from '@material-ui/icons'
import { formatDate } from '../../Utils'

const columns = [
  {
    field: 'status',
    title: 'status',
    lookup: {
      active: 'Streaming',
      scheduled: 'Scheduled',
      inactive: 'Inactive'
    },
    render: rowData => (
      <Chip
        color={rowData.status === 'active' ? 'primary' : 'default'}
        size='small'
        label={rowData.status === 'active' ? 'Streaming' : 'Scheduled'}
        icon={
          rowData.status === 'active' ? (
            <PlayCircleFilled />
          ) : (
            <PauseCircleFilled />
          )
        }
      />
    )
  },
  { title: 'permission', field: 'permission' },
  { title: 'league', field: 'league' },
  {
    title: 'start',
    field: 'timeStreamStart',
    customFilterAndSearch: (term, { dateStart, timeStreamStart }) =>
      formatDate(dateStart, timeStreamStart).includes(term),
    render: ({ dateStart, timeStreamStart }) =>
      formatDate(dateStart, timeStreamStart)
  },
  {
    title: 'end',
    field: 'timeStreamEnd',
    customFilterAndSearch: (term, { dateEnd, timeStreamEnd }) =>
      formatDate(dateEnd, timeStreamEnd).includes(term),
    render: ({ dateEnd, timeStreamEnd }) => formatDate(dateEnd, timeStreamEnd)
  },
  {
    title: 'thingName',
    field: 'thingName',
    render: ({ thingName }) => (
      <Link
        to={{
          pathname: '/devices/' + thingName
        }}
      >
        {thingName}
      </Link>
    )
  },
  { title: 'sport', field: 'typeOfSport' },
  { title: 'site', field: 'site' },
  { title: 'version', field: 'version' },
  {
    title: 'ap det',
    field: 'ap_det'
  },
  { title: 'event-guid', field: 'eventGUID' },
  {
    title: 'content-uuid',
    field: 'contentUUID'
  },
  {
    title: 'feedback',
    render: ({ eventGUID }) => {
      return (
        eventGUID && (
          <a
            href={`https://feedback.sporttotal.tv/events/${eventGUID}`}
            target='_blank'
            rel='noreferrer'
          >
            Feedback
          </a>
        )
      )
    }
  }
]

export default columns
