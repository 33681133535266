import React, { StrictMode, useState, useEffect, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { api } from './Api'

import UserContext from './Context/userContext'
import { SettingsContextProvider } from './Context/settingsContext'

import { handleAuth, fetchUser, refreshTokens } from './Auth'
import Unauthorized from './Auth/Unauthorized'

import Layout from './Components/Layout'
import Spinner from './Components/Spinner'

import { ROUTES } from './routes'

const ONE_HOUR = 3.6e6

const App = _ => {
  const [isConfigLoaded, setIsConfigLoaded] = useState(false)
  const [user, setUser] = useState()

  // -1: loading
  // 0: false
  // 1: true
  const [hasAccess, setHasAccess] = useState(-1)
  const [isRoot, setIsRoot] = useState(false)

  useEffect(() => {
    console.info('🌠 ORCA-AP Started')

    fetch('/api/config/auth')
      .then(res => res.json())
      .then(config => {
        window.config = {}
        window.config.auth = config

        setIsConfigLoaded(true)
      })
  }, [])

  useEffect(() => {
    if (!isConfigLoaded) return
    console.log('🌟 ORCA-AP Config Loaded')

    // Comment handleAuth out to ignore auth locally
    handleAuth()
      .then(async _ => setUser(Object.freeze(await fetchUser())))
      .then(_ => {
        setInterval(refreshTokens, ONE_HOUR)

        api('/api/access').then(({ ok }) => setHasAccess(ok ? 1 : 0))
        api('/api/admin').then(({ ok }) => setIsRoot(ok ? true : false))
      })
      .catch(_ => setUser(null))
  }, [isConfigLoaded])

  if (!isConfigLoaded || !user || hasAccess === -1) return <Spinner />
  if (hasAccess === 0) return <Unauthorized />

  return (
    <StrictMode>
      <Router>
        <UserContext.Provider value={{ ...user, isRoot }}>
          <SettingsContextProvider>
            <Switch>
              <Layout>
                <Suspense fallback={<Spinner />}>
                  {ROUTES.map(({ path, component }) => (
                    <Route exact path={path} component={component} key={path} />
                  ))}
                </Suspense>
              </Layout>
            </Switch>
          </SettingsContextProvider>
        </UserContext.Provider>
      </Router>
    </StrictMode>
  )
}

export default App
