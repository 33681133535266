import React from 'react'
import { CircularProgress, CssBaseline } from '@material-ui/core'

export default _ => (
  <>
    <CssBaseline />
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0'
      }}
    >
      <CircularProgress color='secondary' />
    </div>
  </>
)
