import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import UserContext from '../../Context/userContext'

import {
  List,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'

import {
  ChevronLeft as ChevronLeftIcon,
  Videocam as VideoCamIcon,
  Schedule as ScheduleIcon,
  GridOn as GridOnIcon,
  Visibility as VisibilityIcon,
  LiveTv as LiveTvIcon,
  FitnessCenter as FitnessIcon,
  Check as CheckIcon,
  VpnKey as KeyIcon
} from '@material-ui/icons/'
import styles from './styles'

const STANDARD_ROUTES = [
  { text: 'Devices', icon: <VideoCamIcon />, path: '/devices' },
  { text: 'Schedule', icon: <ScheduleIcon />, path: '/events' },
  {
    text: 'Camera Overview',
    icon: <VisibilityIcon />,
    path: '/camera-overview'
  },
  { text: 'Mosaic', icon: <GridOnIcon />, path: '/mosaic' },
  { text: 'Livestream', icon: <LiveTvIcon />, path: '/livestream' },
  { text: 'Rules', icon: <CheckIcon />, path: '/rules' },
  { text: 'Batch', icon: <FitnessIcon />, path: '/batch' }
]

const ADMIN_ROUTES = [
  ...STANDARD_ROUTES,
  { text: 'Authz', icon: <KeyIcon />, path: '/authz' }
]

const Nav = ({ open, handleDrawerClose }) => {
  const classes = styles()
  const { isRoot } = useContext(UserContext)

  const NavItems = (
    <div>
      {(isRoot ? ADMIN_ROUTES : STANDARD_ROUTES).map(
        ({ text, icon, path }, index) => (
          <NavLink key={index} to={path} className={classes.unsetAnchor}>
            <ListItem button>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          </NavLink>
        )
      )}
    </div>
  )

  return (
    <Drawer
      variant='permanent'
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>{NavItems}</List>
      <Divider />
    </Drawer>
  )
}

export default Nav
