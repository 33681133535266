import { fetchTokens } from './Auth/store'

export const api = request => {
  const { token } = fetchTokens()

  const headers = new Headers({
    Authorization: `Bearer ${token}`
  })

  if (request.headers) {
    request.headers.forEach((value, key) => {
      headers.append(key, value)
    })
  }

  const apiRequest = new Request(request, {
    headers
  })

  return fetch(apiRequest)
}
