const objectsOnlyFilter = ([_, v]) => typeof v !== 'object'

const arrayToObjectMapper = ([key, value]) => {
  return {
    key,
    value
  }
}

export { objectsOnlyFilter, arrayToObjectMapper }
