import createReducer from './createReducer'

const TOGGLE_LOCK = ({ lock, ...others }) => {
  window.localStorage.setItem('lock', !lock)
  return { ...others, lock: !lock }
}

const CHANGE_DEVICE_LAYOUT = (state, { payload }) => {
  window.localStorage.setItem('layout', payload)
  return { ...state, layout: payload }
}

export default createReducer({
  TOGGLE_LOCK,
  CHANGE_DEVICE_LAYOUT
})
