import React, { useState, useEffect, useRef } from 'react'
import socket from '../../ws'
import useInterval from '@use-it/interval'
import MaterialTable from 'material-table'
import columns from './columns'
import tableIcons from './icons'
import { api } from '../../Api'

const Events = _ => {
  const tableRef = useRef()
  const [events, setEvents] = useState([])
  const [autoprodState, setAutoprodState] = useState([])

  const mergeEventsAndApState = events =>
    events.map(({ thingName, ...others }) => {
      const eventApState = autoprodState[thingName]
      const { player_avg, ball_avg } = eventApState || {}

      const ap_det = eventApState && `${player_avg} Players, ${ball_avg} Balls`

      return {
        ...others,
        thingName,
        ap_det: ap_det || ''
      }
    })

  const fetchEvents = _ => {
    api('/api/events')
      .then(r => r.json())
      .then(ev => {
        setEvents(mergeEventsAndApState(ev.filter(e => e.thingName)))
      })
      .catch(console.error)
  }

  useEffect(_ => {
    fetchEvents()
    socket.on(`CAMERA_DETECTION_STATE_UPDATED`, setAutoprodState)
    return _ => socket.off(`CAMERA_DETECTION_STATE_UPDATED`)
  }, [])

  useEffect(() => {
    setEvents(currentEvents => mergeEventsAndApState(currentEvents))
  }, [autoprodState])

  useInterval(fetchEvents, 60000)

  return (
    <MaterialTable
      tableRef={tableRef}
      icons={tableIcons}
      columns={columns}
      title="Today's Events"
      data={events}
      options={{
        sorting: true,
        filtering: true,
        pageSize: events.length > 100 ? 100 : 50,
        exportButton: true,
        exportAllData: true
      }}
    />
  )
}

export default Events
