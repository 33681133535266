import React, { createContext as createReactContext, useReducer } from 'react'

const createContext = (reducer, defaultValue) => {
  const Context = createReactContext()
  const ContextConsumer = Context.Consumer

  const ContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue)
    const contextValue = { state, dispatch }

    return <Context.Provider value={contextValue}>{children}</Context.Provider>
  }

  return { Context, ContextConsumer, ContextProvider }
}

export default createContext
