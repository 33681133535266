import {
  redeemCode as redeemCodeRequest,
  refreshTokens as refreshTokensRequest,
  fetchUser as fetchUserRequest
} from './api'
import { fetchTokens, setTokens, clearTokens } from './store'
import { ORCA_PARAMS, AuthEndpoints } from './constants'

export const redeemCode = async _ => {
  const code = ORCA_PARAMS.get('code')
  const { refreshToken, accessToken, expiresIn } = await redeemCodeRequest(code)

  setTokens({ refreshToken, accessToken, expiresIn })
}

export const refreshTokens = async _ => {
  const { refreshToken: currentRefreshToken } = fetchTokens()

  const { refreshToken, accessToken, expiresIn } = await refreshTokensRequest(
    currentRefreshToken
  )

  setTokens({ refreshToken, accessToken, expiresIn })
}

export const fetchUser = async _ => {
  const { token } = fetchTokens()
  return fetchUserRequest(token)
}

export const handleAuth = async _ => {
  const code = ORCA_PARAMS.get('code')
  const { token, refreshToken, tokenExpiresOn } = fetchTokens()

  const isRefreshTokenExpired =
    tokenExpiresOn && Date.now() > new Date(parseInt(tokenExpiresOn))

  if (!code && (!refreshToken || !token)) {
    // No Code or tokens found, user needs to login
    window.location.replace(AuthEndpoints.LOGIN_REDIRECT)
  }

  if (isRefreshTokenExpired) {
    // Token is expired, clear all local tokens and redirect to login
    clearTokens()
    window.location.replace(AuthEndpoints.LOGIN_REDIRECT)
  }

  if (code && (!token || !refreshToken)) {
    // User Logged in but no tokens found in LocalStorage
    await redeemCode()
  }

  if (!isRefreshTokenExpired) {
    // Refresh tokens on initial login
    await refreshTokens()
  }
}

export const handleSignOut = _ => {
  clearTokens()
  window.location.replace(AuthEndpoints.SIGN_OUT_REDIRECT)
}
