import createContext from './createContext'
import settingsReducer from '../Reducers/settingsReducer'

const savedLayout = window.localStorage.getItem('layout')
// const savedLockState = window.localStorage.getItem('lock')

const lock = false //savedLockState === 'false' ? false : true

const DEFAULT_VALUE = {
  layout: savedLayout || 'Operations',
  lock
}

const {
  Context: SettingsContext,
  ContextProvider: SettingsContextProvider,
  ContextConsumer: SettingsContextConsumer
} = createContext(settingsReducer, DEFAULT_VALUE)

export { SettingsContext, SettingsContextProvider, SettingsContextConsumer }
